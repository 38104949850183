<script setup lang="ts">
import { useNuxtApp } from "#app";
import { ResizeType } from "@bitpatty/imgproxy-url-builder";

const props = defineProps({
    title: String,
    date: String,
    pictureUrl: String,
    detailUrl: String,
    skeleton: Boolean,
    notLazyLoad: Boolean,
    targetBlank: Boolean
});
const nuxtApp = useNuxtApp();
</script>

<template>
    <nuxt-link v-if="skeleton === false" :to="detailUrl" class="relative rounded-lg overflow-hidden">
        <div class="absolute bottom-0 left-0 p-6 z-10">
            <h2 class="text-xl font-semibold text-white">{{ title }}</h2>
            <div class="flex items-center mt-4 cursor-pointer text-white">
                <p class="pr-2 text-sm font-medium leading-none">Read More</p>
                <svg class="fill-stroke" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.75 12.5L10.25 8L5.75 3.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div>
        <picture class="block relative h-full">
            <source media="(max-width: 766px)" type="image/webp" :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 300, 350, 'webp', ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 600, 700, 'webp', ResizeType.FILL)} 2x`">
            <source media="(max-width: 766px)" :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 300, 350, undefined, ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 600, 700, undefined, ResizeType.FILL)} 2x`">
            <source media="(min-width: 767px)" type="image/webp" :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 350, 400, 'webp', ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 700, 800, 'webp', ResizeType.FILL)} 2x`">
            <source media="(min-width: 767px)" :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 350, 400, undefined, ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 700, 800, undefined, ResizeType.FILL)} 2x`">
            <img
                :loading="notLazyLoad ? 'eager' : 'lazy'"
                width="350"
                height="350"
                class="w-full h-full bg-soft-white-100 object-cover"
                :src="nuxtApp.imgProxyResize(pictureUrl, 350, 400, undefined, ResizeType.FILL)"
                :alt="title"
            >
            <span class="absolute block inset-0 bg-black opacity-40"></span>
        </picture>
    </nuxt-link>
    <div v-else class="relative rounded-lg overflow-hidden">
        <div class="absolute bottom-0 left-0 p-6 sm:p-10 z-10 w-full">
            <span class="animate-pulse block rounded h-4 w-[30%] bg-gray-200"></span>
            <span class="animate-pulse block rounded h-4 w-[50%] bg-gray-200 mt-4"></span>
            <span class="animate-pulse block rounded h-4 w-[40%] bg-gray-200 mt-4"></span>
        </div>
        <div class="relative w-full h-full">
            <div class="animate-pulse h-full w-full rounded-lg bg-gray-50"></div>
        </div>
    </div>
</template>