<script setup lang="ts">
import { useAsyncData, useNuxtApp } from "#app";
import { useHomeArticleCollectionStore } from "~/stores/home/homeArticleCollection";
import { computed } from "vue";

const props = defineProps({
    notLazyLoad: Boolean
});
const homeArticleCollectionStore = useHomeArticleCollectionStore();
const nuxtApp = useNuxtApp();

useAsyncData('homeArticleCollectionStore', async () => homeArticleCollectionStore.fetchList());

const columns = computed(() => homeArticleCollectionStore.getColumns);
</script>

<template>
    <section class="container space-y-6">
        <div class="grid justify-items-end">
            <nuxt-link v-if="columns !== undefined" to="/interesting-facts" class="font-semibold text-indigo-800">Interesting Facts <span aria-hidden="true">→</span></nuxt-link>
            <span v-else class="animate-pulse rounded block h-6 w-[10vh] bg-indigo-50"></span>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
            <ul class="grid grid-flow-row space-y-4 justify-stretch">
                <CardArticleListBlock v-if="columns !== undefined"
                                      v-for="item in columns[0]"
                                      :key="item.slug"
                                      :title="item.title"
                                      :date="item.date"
                                      :category="item.category"
                                      :picture-url="item.picture"
                                      :detail-url="item.url"
                />
                <CardArticleListBlock v-else
                                      v-for="index in 3"
                                      :key="index"
                                      :skeleton="true"
                />
            </ul>
            <CardArticleImageBackgroundFullHeight v-if="columns !== undefined"
                                                  v-for="item in columns[1]"
                                                  :key="item.slug"
                                                  :title="item.title"
                                                  :picture-url="item.picture"
                                                  :detail-url="item.url"
            />
            <CardArticleImageBackgroundFullHeight v-else
                                                  v-for="index in 2"
                                                  class="min-h-[10rem]"
                                                  :key="index"
                                                  :skeleton="true"
            />
            <ul class="grid grid-flow-row space-y-4 justify-stretch">
                <CardArticleListBlock v-if="columns !== undefined"
                                      v-for="item in columns[2]"
                                      :key="item.slug"
                                      :title="item.title"
                                      :date="item.date"
                                      :category="item.category"
                                      :picture-url="item.picture"
                                      :detail-url="item.url"
                                      :image-reverse="true"
                />
                <CardArticleListBlock v-else
                                      v-for="index in 3"
                                      :key="index"
                                      :image-reverse="true"
                                      :skeleton="true"
                />
            </ul>
        </div>
    </section>
</template>