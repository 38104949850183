<script setup lang="ts">
import { computed, onUnmounted } from "vue";
import { ArrowPathRoundedSquareIcon } from "@heroicons/vue/24/outline";
import { useRecipePackALunchCollectionStore } from "~/stores/recipe/recipePackALunchCollection";
import { useAsyncData } from "#app";

const props = defineProps({
    notLazyLoad: Boolean,
});

const recipePackALunchCollectionStore = useRecipePackALunchCollectionStore();
useAsyncData('recipePackALunchCollection', async () => recipePackALunchCollectionStore.fetchList())
const items = computed(() => recipePackALunchCollectionStore.list);

const reload = () => {
    recipePackALunchCollectionStore.fetchList();
}

const badges = [
    {color: 'red', title: 'First'},
    {color: 'yellow', title: 'Snack'},
    {color: 'green', title: 'Second'},
    {color: 'purple', title: 'Sweets'},
];

onUnmounted(() => {
    recipePackALunchCollectionStore.clear();
});
</script>

<template>
    <section class="container flex lg:flex-row flex-col items-center space-y-12 md:space-y-16 lg:space-y-0">
        <div class="lg:flex-grow lg:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left items-center text-center space-y-6 md:space-y-12">
            <h1 class="title-font text-xl sm:text-4xl font-extrabold">Take a Chance and Discover a Delectable Dish for Your Evening Meal</h1>
            <p class="mb-8 sm:leading-relaxed font-medium text-md sm:text-lg"> Indulge in a diverse array of delectable dishes specially curated for your culinary pleasure tonight. Should any of the suggested options not quite tickle your taste buds, why not take a leap of faith and click the "Try your luck" button? Allow us to unveil a surprise selection of tantalizing dishes that will delight your palate.</p>
            <div class="flex justify-center">
                <button class="inline-flex items-center text-white bg-indigo-800 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                        @click="reload"
                >
                    <ArrowPathRoundedSquareIcon class="w-6 h-6 text-white mr-2" />
                    Try your luck
                </button>
            </div>
<!--            <ClientOnly>
                <div class="hidden sm:flex container justify-center">
                    <AdsenseSmallHorizontal/>
                </div>
                <div class="sm:hidden container flex justify-center">
                    <AdsenseMedia/>
                </div>
            </ClientOnly>-->
        </div>
        <div v-if="items !== undefined && items.length" class="w-full lg:max-w-lg md:w-full lg:w-1/2 grid grid-cols-2 sm:grid-cols-2 gap-4 sm:gap-8">
            <div v-for="(item, index) in items" :key="item.slug" class="relative">
                <span :class="`absolute -left-[0.7rem] md:-left-[0.7rem] -top-[0.7rem] md:-top-[0.7rem] inline-flex items-center rounded-md bg-${badges[index].color}-50 px-2 py-1 text-xs font-medium text-${badges[index].color}-700 ring-1 ring-inset ring-${badges[index].color}-600/10`">{{ badges[index].title }}</span>
                <CardRecipe class="h-full" :not-lazy-load="notLazyLoad && index === 0" target-blank :title="item.title" :time="item.totalTime" :picture-url="item.mainPicture" :detail-url="item.url"/>
            </div>
        </div>
        <div  v-else-if="items === undefined" class="w-full lg:max-w-lg md:w-full lg:w-1/2 grid grid-cols-2 sm:grid-cols-2 gap-4 sm:gap-8">
            <div class="p-2 relative">
                <span class="absolute -left-[0.3rem] -top-[0.3rem] inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">First</span>
                <CardRecipe class="h-full" :skeleton="true"/>
            </div>
            <div class="p-2 relative">
                <span class="absolute -left-[0.3rem] -top-[0.3rem] inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">Salad</span>
                <CardRecipe class="h-full" :skeleton="true"/>
            </div>
            <div class="p-2 relative">
                <span class="absolute -left-[0.3rem] -top-[0.3rem] inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10">Second</span>
                <CardRecipe class="h-full" :skeleton="true"/>
            </div>
            <div class="p-2 relative">
                <span class="absolute -left-[0.3rem] -top-[0.3rem] inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-600/10">Sweets</span>
                <CardRecipe class="h-full" :skeleton="true"/>
            </div>
        </div>
    </section>
</template>