<script setup lang="ts">
import { computed } from "vue";
import { useRecipeCategoryCollectionStore } from "~/stores/recipe/recipeCategoryCollection";

defineProps({
    selected: String,
});

const recipeCategoryCollectionStore = useRecipeCategoryCollectionStore();

useAsyncData('navFeatureRecipeCategories', async () => recipeCategoryCollectionStore.fetchList());
const items = computed(() => recipeCategoryCollectionStore.list);
</script>

<template>
    <section v-if="items !== undefined && items.length > 0" class="text-gray-600 body-font">
        <div class="container px-5 mx-auto">
            <div class="flex items-center flex-wrap -m-2">
                <nuxt-link v-for="item in items" :key="item.slug" :to="item.url" class="p-2 md:w-1/4 block hover:underline underline-offset-4">
                    <div class="flex h-full p-2 flex-col">
                        <div class="flex items-center">
                            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center flex-shrink-0"
                                 v-html="item.svgInline"
                            ></div>
                            <h2 class="text-lg title-font font-medium">{{ item.title }}</h2>
                        </div>
                    </div>
                </nuxt-link>
            </div>
        </div>
    </section>
</template>