import { defineStore } from 'pinia';
import { useNuxtApp } from "#app";
import { RecipeCollectionType } from "~/types/recipe/RecipeCollectionType";
import { RecipePreviewType } from "~/types/recipe/RecipePreviewType";
import { timeSpentFormatter } from "~/utils/formatters/date.formatter";
import { RecipeDetailType } from "~/types/recipe/RecipeDetailType";

export const useRecipePackALunchCollectionStore = defineStore({
    id: 'recipePackALunchCollection',
    state: (): RecipeCollectionType => ({
        list: undefined,
        pagination: undefined,
        error: undefined,
        skeleton: false,
    }),
    getters: {},
    actions: {
        clear() {
            this.list = undefined;
            this.pagination = undefined;
            this.error = undefined;
        },

        enableSkeleton() {
            this.skeleton = true;
        },

        async fetchList() {
            const config = useRuntimeConfig();

            try {
                const response = await useNuxtApp().axios.get('/recipes/pack-a-lunch');

                if (response?.data?.data === undefined) {
                    this.error = createError({ statusCode: 404, statusMessage: 'Page Not Found' });
                    this.pagination = undefined;
                    this.skeleton = false;
                    return false;
                }

                if (!response?.data?.data.length) {
                    this.list = [];
                    this.skeleton = false;
                    this.pagination = undefined;
                    return true;
                }

                this.list = response?.data?.data.map((item) => {
                    return <RecipePreviewType>{
                        slug: item?.attributes?.slug,
                        title: item?.attributes?.title,
                        prepTime: item?.attributes?.prep_time,
                        cookingTime: item?.attributes?.cooking_time,
                        totalTime: timeSpentFormatter(item?.attributes?.prep_time + item?.attributes?.cooking_time),
                        category: item?.attributes?.recipe_category?.data?.attributes?.title,
                        mainPicture: config.public.ssrMediaBaseUrl + item?.attributes?.main_picture?.data?.attributes?.url,
                        url: `/recipes/recipe-${item?.attributes?.slug}`
                    };
                });
                this.skeleton = false;
                this.error = undefined;
            } catch (error) {
                console.log(error);
                this.error = createError({ statusCode: 500, statusMessage: 'Internal error' });
            }

            return true;
        }
    },
});
