<script setup lang="ts">
import { useNuxtApp } from "#app";
import { ResizeType } from "@bitpatty/imgproxy-url-builder";

const props = defineProps({
    title: String,
    date: String,
    category: String,
    pictureUrl: String,
    detailUrl: String,
    skeleton: Boolean,
    notLazyLoad: Boolean,
    targetBlank: Boolean,
    imageReverse: Boolean,
});
const nuxtApp = useNuxtApp();
</script>

<template>
    <li v-if="skeleton === false" class="flex flex-row">
        <nuxt-link :to="detailUrl" :target="targetBlank ? '_blank' : ''" class="bg-gray-100 rounded-lg flex flex-1 items-center p-4">
            <picture v-if="!imageReverse" class="rounded-md w-10 h-10 bg-gray-300 mr-4">
                <source type="image/webp" :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 40, 40, 'webp', ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 80, 80, 'webp', ResizeType.FILL)} 2x`">
                <source :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 40, 40, undefined, ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 80, 80, undefined, ResizeType.FILL)} 2x`">
                <img
                    :loading="notLazyLoad ? 'eager' : 'lazy'"
                    width="40"
                    height="40"
                    class="w-full bg-soft-white-100 rounded-lg"
                    :src="nuxtApp.imgProxyResize(pictureUrl, 40, 40, undefined, ResizeType.FILL)"
                    :alt="title"
                >
            </picture>
            <div class="flex-1 pl-1">
                <div class="font-medium">{{ title }}</div>
                <div class="text-gray-600 text-sm">{{ category }}</div>
            </div>
            <picture v-if="imageReverse" class="rounded-md w-10 h-10 bg-gray-300 ml-4">
                <source type="image/webp" :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 40, 40, 'webp', ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 80, 80, 'webp', ResizeType.FILL)} 2x`">
                <source :srcset="`${nuxtApp.imgProxyResize(pictureUrl, 40, 40, undefined, ResizeType.FILL)} 1x, ${nuxtApp.imgProxyResize(pictureUrl, 80, 80, undefined, ResizeType.FILL)} 2x`">
                <img
                    :loading="notLazyLoad ? 'eager' : 'lazy'"
                    width="40"
                    height="40"
                    class="w-full bg-soft-white-100 rounded-lg"
                    :src="nuxtApp.imgProxyResize(pictureUrl, 40, 40, undefined, ResizeType.FILL)"
                    :alt="title"
                >
            </picture>
        </nuxt-link>
    </li>
    <li v-else class="border-gray-400 flex flex-row">
        <div class="bg-gray-50 rounded-lg flex flex-1 items-center p-4">
            <div v-if="!imageReverse" class="rounded-md w-10 h-10 mr-4 animate-pulse bg-gray-200"></div>
            <div class="flex-1 pl-1">
                <div class="animate-pulse rounded h-4 w-[70%] bg-gray-200"></div>
                <div class="animate-pulse rounded h-3 w-[40%] bg-gray-200 mt-2"></div>
            </div>
            <div v-if="imageReverse" class="rounded-md w-10 h-10 ml-4 animate-pulse bg-gray-200"></div>
        </div>
    </li>
</template>