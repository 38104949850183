import { defineStore } from 'pinia';
import { useNuxtApp } from "#app";
import { ArticleCollectionType } from "~/types/article/ArticleCollectionType";
import { ArticlePreviewEntity } from "~/entities/article/ArticlePreviewEntity";

export const useHomeArticleCollectionStore = defineStore({
    id: 'homeArticleCollection',
    state: (): ArticleCollectionType => ({
        list: undefined,
        error: undefined,
    }),
    getters: {
        getColumns(state: ArticleCollectionType) {
            if (state.list === undefined) {
                return undefined;
            }

            return [
                state.list?.slice(2, 5),
                state.list?.slice(0, 2),
                state.list?.slice(5, 8),
            ];
        }
    },
    actions: {
        clear() {
            this.list = undefined;
            this.error = undefined;
        },

        async fetchList() {
            const config = useRuntimeConfig();

            try {
                const response = await useNuxtApp().axios.get('/interesting-facts/home-page');

                if (response?.data?.data === undefined) {
                    this.error = createError({ statusCode: 404, statusMessage: 'Page Not Found' });
                    this.skeleton = false;
                    return false;
                }

                if (!response?.data?.data.length) {
                    this.list = [];
                    this.skeleton = false;
                    return true;
                }

                this.list = response?.data?.data.map((item) => ArticlePreviewEntity(item, config));
                this.error = undefined;
            } catch (error) {
                console.log(error);
                this.error = createError({ statusCode: 500, statusMessage: 'Internal error' });
            }

            return true;
        }
    },
});
