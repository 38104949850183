import { defineStore } from 'pinia';
import { useNuxtApp } from "#app";
import { setMeta } from "~/utils/meta";
import { DefaultPageType } from "~/types/page/DefaultPageType";
import { useRoute } from "vue-router";

export const useHomePageStore = defineStore({
    id: 'homePage',
    state: (): DefaultPageType => ({
        error: undefined,
    }),
    getters: {},
    actions: {
        clear() {
            this.error = undefined;
        },

        async fetch() {
            const route = useRoute();
            const config = useRuntimeConfig();

            try {
                const response = await useNuxtApp().axios.get('/home-page', {
                    params: {
                        'populate[seo][populate][0]': 'metaImage',
                    }
                });
                if (response?.data?.data === undefined) {
                    this.error = createError({ statusCode: 404, statusMessage: 'Page Not Found' });
                    return false;
                }

                const data = response?.data?.data;

                if (data.attributes?.seo) {
                    setMeta(data.attributes?.seo, config, route);
                }
            } catch (error) {
                console.log(error);
                this.error = createError({ statusCode: 500, statusMessage: 'Internal error' });
            }

            return true;
        },
    },
});
